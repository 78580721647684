<template>
	<div class="header">
		<div class="collapse-btn" @click="collapseChage">
			<i v-if="!collapse" class="el-icon-s-fold"></i>
			<i v-else class="el-icon-s-unfold"></i>
		</div>
		<div class="header-right">
			<div class="header-user-con">
				<div v-if="ms_role == 'admin'">
					<img src="../assets/img/shengy1.png" alt="" v-if="total > 0"
						style="width: 20px;height: 20px;cursor: pointer;margin-right: 16px;" @click="towoniuRecharge" />
					<img src="../assets/img/shengy2.png" alt="" v-else
						style="width: 20px;height: 20px;cursor: pointer;margin-right: 16px;" @click="towoniuRecharge" />
				</div>
				<!-- <i class="el-icon-message-solid" style="font-size: 20px;" v-else></i> -->
				<div class="download-box2" v-if="deviceName == null">
					<i class="el-icon-s-platform" :class="[theme3]"></i>
					<span style="margin-left: 6px;">未连接设备</span>
				</div>
				<div class="download-box2" v-else>
					<i class="el-icon-s-platform" :class="[theme3]"></i>
					<span style="margin-left: 6px; color: #67C23A;">已连接{{ deviceName }}设备</span>
				</div>
				<div class="download-box2" v-if="setCallStatus">
					<i class="el-icon-s-platform" :class="[theme3]"></i>
					<span style="margin-left: 6px; color: #67C23A;">话机{{setCallStatus}}</span>
				</div>
				<div class="download-box">
					<i class="el-icon-mobile-phone" :class="[theme3]"></i>
					下载APP
					<div class="img-box">
						扫码下载app
						<img :src="webInfo.downUrl" alt="" />
					</div>
				</div>
				<img src="../assets/img/pifu.svg" :class="[theme3]"
					style="width: 24px;height: 36px;cursor: pointer;fill: red" @click="toSetColor" title="主题切换" />

				<!-- 用户头像 -->
				<div class="user-avator"><img :src="headerUrl" /></div>
				<!-- 用户名下拉菜单 -->
				<el-dropdown class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link">
						{{ username }}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item divided command="editPassword">修改密码</el-dropdown-item>
							<el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
	</div>
	<el-dialog title="修改密码" v-model="addVisible" width="30%" @close="closeDialog('form')">
		<el-form :model="form" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="账号" prop="account"><el-input size="small" v-model="form.account"
					disabled></el-input></el-form-item>
			<el-form-item label="原密码" prop="oldPassword"><el-input size="small"
					v-model="form.oldPassword"></el-input></el-form-item>
			<el-form-item label="新密码" prop="newPassword"><el-input size="small"
					v-model="form.newPassword"></el-input></el-form-item>
			<el-form-item label="密码确认" prop="checkPass"><el-input size="small"
					v-model="form.checkPass"></el-input></el-form-item>
		</el-form>
		<span class="dialog-footer myRight">
			<el-button size="small" type="primary" :class="[theme]" @click="confirmEdit('form')">确定</el-button>
			<el-button size="small" @click="closeDialog('form')">取消</el-button>
		</span>
	</el-dialog>
	<audio id="audioID" ref="audio" class="aud" @ended="audioEnded">
		<source :src="audioUrl" />
	</audio>
</template>
<script>
	import { modifyPassword } from '../api/userIndex';
	import { webGetHeadPortrait, getConnection, getcount } from '../api/sumIndex.js';
	import { getOemInfo } from '../api/index.js';
	import { syncLocalStorage } from '@/utils/storage';

	export default {
		data() {
			var validateOldPass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入原密码'));
				}
				callback();
			};
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入新密码'));
				} else {
					if (this.form.checkPass !== '') {
						this.$refs.form.validateField('checkPass');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.form.newPassword) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				fullscreen: false,
				addVisible: false,
				form: {
					account: localStorage.getItem('ms_username'),
					oldPassword: '',
					newPassword: '',
					checkPass: ''
				},
				name: 'linxin',
				message: 2,
				rules: {
					oldPassword: [{ validator: validateOldPass, trigger: 'blur' }],
					newPassword: [{ validator: validatePass, trigger: 'blur' }],
					checkPass: [{ validator: validatePass2, trigger: 'blur' }]
				},

				headerUrl: '',
				deviceName: null,
				sipStatus: false,
				audioUrl: 'https://phone-recorder.oss-cn-hangzhou.aliyuncs.com/yunqitongxcx/static/czdd.mp3', //音频资源
				audioOpen: false,
				yuying: null,
				webInfo: '',
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				a: 0,
				total: '',
				ms_role: localStorage.getItem('ms_role'),

			};
		},
		computed: {
			username() {
				let username = localStorage.getItem('ms_username');
				return username ? username : this.name;
			},
			collapse() {
				return this.$store.state.collapse;
			},
			setCallStatus() {
				return this.$store.state.setCallStatus;
			}
		},

		methods: {

			getOEMagentOem() {
				// let url = 'cytx88.cn';
				let url = window.location.host;
				getOemInfo(url)
					.then(res => {
						if (res.code == 200) {
							this.webInfo = res.data;
							let linkicon = document.querySelector("link[rel*='icon']") || document.createElement(
								'link');
							linkicon.href = res.data.icon;
							localStorage.setItem('webName', res.data.webName);
						} else {
							this.$message.error('查询失败，请稍后再试！');
						}
					})
					.catch(err => {
						this.$message.error('查询失败，请稍后再试！');
					});
			},
			confirmEdit(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						modifyPassword(this.form).then(res => {
							if (res.code === 200) {
								this.$message.success('修改成功');
								this.handleCommand('loginout');
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
						});
					}
				});
			},
			toSetColor() {
				this.$router.push('/setColor');
			},
			// 用户名下拉菜单选择事件
			handleCommand(command) {
				if (command === 'loginout') {
					localStorage.removeItem('interior');
					localStorage.removeItem('ms_role');
					localStorage.removeItem('ms_username');
					localStorage.removeItem('user');
					localStorage.removeItem('token');
					localStorage.removeItem('deviceName');
					// localStorage.clear();
					clearInterval(this.yuying);
					this.$router.push('/login');
				}
				if (command === 'editPassword') {
					this.addVisible = true;
				}
			},
			towoniuRecharge() {
				this.$router.push('/woniuRecharge');
			},
			// 侧边栏折叠
			collapseChage() {
				this.$store.commit('hadndleCollapse', !this.collapse);
			},
			closeDialog(formName) {
				this.addVisible = false;
				(this.form = {
					account: localStorage.getItem('ms_username')
				}),
				this.resetForm(formName);
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			getConnection() {
				let data = {
					name: localStorage.getItem('ms_username')
				};
				getConnection(data).then(res => {
					if (res.data.deviceName == '') {
						localStorage.removeItem('deviceName');
						localStorage.removeItem('AppInfoIp');
						this.deviceName = null;
					} else {
						localStorage.setItem('AppInfoIp', res.data.ip);
						localStorage.setItem('deviceName', res.data.deviceName);
						this.deviceName = localStorage.getItem('deviceName');
					}
				});
			},
			getcount() {
				let data = {
					userId: localStorage.getItem('user')
				};
				getcount(data).then(res => {
					this.total = res.data;
					if (res.data > 0) {
						let audioElement = new Audio(
							'https://phone-recorder.oss-cn-hangzhou.aliyuncs.com/yunqitongxcx/static/czdd.mp3');
						audioElement.play();
					}
					// if (res.data > 0) {
					// 	if (this.a == 0) {
					// 		let audioElement = new Audio('https://phone-recorder.oss-cn-hangzhou.aliyuncs.com/yunqitongxcx/static/czdd.mp3');
					// 		audioElement.play();
					// 		this.a++;
					// 		console.log(this.a, '1111');
					// 		/* 给铃铛赋值 显示几条未处理 res.data的值*/
					// 		this.total = res.data;
					// 	} else {
					// 		/* 给铃铛赋值 显示几条未处理*/
					// 		this.total = res.data;
					// 	}
					// } else {
					// 	this.a = 0;
					// }
				});
			}
		},
		created() {
			let data = {
				currentUserId: localStorage.getItem('user')
			};
			webGetHeadPortrait(data).then(res => {
				let role = localStorage.getItem('ms_role');
				if (role == 'admin') {
					this.yuying = setInterval(res => {
						this.getcount();
					}, 10000);
				}
				this.headerUrl = res.data.headPortrait;
				if (res.data.computerDial == true) {
					if (role === 'normal') {
						setInterval(res => {
							this.getConnection();
						}, 2000);
					}
				}
			});
			this.getOEMagentOem();
		},
		mounted() {
			if (document.body.clientWidth < 1500) {
				this.collapseChage();
			}
		},

	};
</script>
<style scoped>
	.header {
		position: relative;
		box-sizing: border-box;
		/* width: calc(100%-216px); */

		height: 70px;
		font-size: 22px;
		color: #666;
	}

	.collapse-btn {
		float: left;
		padding: 0 21px;
		cursor: pointer;
		line-height: 70px;
	}

	.collapse-btn .el-icon-s-fold {
		position: absolute;
		left: 216px;
		line-height: 70px;
		z-index: 9999;
		transition: left 0.3s ease-in-out;
	}

	.collapse-btn .el-icon-s-unfold {
		position: absolute;
		left: 75px;
		line-height: 70px;
		z-index: 9999;
		transition: left 0.3s ease-in-out;
	}

	.header .logo {
		float: left;
		width: 232px;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #6c6cf9;
		margin-left: 10px;
	}

	.header .logo img {
		width: 100px;
	}

	.header-right {
		float: right;
		padding-right: 25px;
	}

	.header-user-con {
		display: flex;
		height: 70px;
		align-items: center;
	}

	.erweima {
		width: 26px;
		height: 26px;
		overflow: hidden;
	}

	.erweima img {
		width: 100%;
		height: 100%;
	}

	.btn-fullscreen {
		transform: rotate(45deg);
		margin-right: 5px;
		font-size: 24px;
	}

	.btn-bell,
	.btn-fullscreen {
		position: relative;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 15px;
		cursor: pointer;
	}

	.btn-bell-badge {
		position: absolute;
		right: 0;
		top: -2px;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background: #f56c6c;
		color: #666;
	}

	.btn-bell .el-icon-bell {
		color: #666;
	}

	.user-name {
		margin-left: 10px;
	}

	.user-avator {
		margin-left: 20px;
		margin-right: 10px;
	}

	.user-avator img {
		display: block;
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.el-dropdown-link {
		color: #666;
		cursor: pointer;
	}

	.el-dropdown-menu__item {
		text-align: center;
	}

	.download-box2 {
		position: relative;
		margin-right: 20px;
		cursor: pointer;
	}

	.download-box {
		position: relative;
		margin-right: 20px;
	}

	.download-box .img-box {
		width: 140px;
		height: 165px;
		background-color: #fff;
		display: none;
		box-shadow: 0 5px 10px #ddd;
	}

	.download-box .img-box img {
		width: 120px;
		margin: 0 10px 10px 10px;
	}

	.download-box:hover {
		color: #4f7afd;
		cursor: pointer;
	}

	.download-box:hover i {
		color: #4f7afd;
	}

	.download-box:hover .img-box {
		text-align: center;
		line-height: 36px;
		display: block;
		position: absolute;
		top: 30px;
		left: -35px;
		color: #000 !important;
		z-index: 999;
	}
</style>